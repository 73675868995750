import React, { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { COOKIE_AUTH } from '../../../constants/cookies';
import { PATH } from '../../../routes/CustomRoutes';
import { URL_AUTH } from '../../../constants/urls';
import { POST } from '../../../frameworks/HttpClient';
import useUserData from '../../../hooks/useUserData';
import { useTranslation } from 'react-i18next';

const LogoutButton = ({ isLiff = false }) => {  // Set default to false
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = useUserData();
  const { t } = useTranslation();

  const handleLogout = async () => {
    setLoading(true);
    setError(null);
    try {
      await POST(URL_AUTH.SIGN_OUT, { is_liff: isLiff });
    } catch (error) {
      console.error("Logout failed:", error);
      setError(t("logout_failed_Please_try_again."));
      return;
    } finally {
      setLoading(false);
      Cookies.remove(COOKIE_AUTH.TOKEN);
      user.removeUserData();
      const page = isLiff ? PATH.LIFF_LANDING : PATH.KIOSK;
      navigate(page);
    }
  };

  return (
    <Button onClick={handleLogout} loading={loading} className="button-17" disabled={loading}>
      {t("logout")}
    </Button>
  );
};

// Set default props
LogoutButton.defaultProps = {
  isLiff: false,
};

export default LogoutButton;
