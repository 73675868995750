import React from "react"
import propTypes from "prop-types";
import { Alert, Button, Checkbox, Col, Drawer, Form, Input, message, Row, theme } from "antd"
import SegmentHeader from "../common/SegmentHeader";
import { DollarOutlined, EditOutlined } from "@ant-design/icons";
import DiscountSegment from "./DiscountSegment";
import PaymentMethodSegment from "./PaymentMethodSegment";
import { PATCH, POST } from "../../frameworks/HttpClient";
import { URL_SALE } from "../../constants/urls";
import { currencyFormat } from "../../frameworks/Util";
import PaymentModal from "./PaymentModal";

export default function PaymentDrawer(props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [isLoading, setLoading] = React.useState(false);
	const [discount, setDiscount] = React.useState(0);
	const [payment, setPayment] = React.useState(0);
	const [paid, setPaid] = React.useState(0);
	// Payment list
	const [discountList, setDiscountList] = React.useState([]);
	const [paymentMethodList, setPaymentMethodList] = React.useState([]);
	// Handle modal
	const [openModal, setOpenModal] = React.useState(false);
	const {
    token: { colorWarning, colorInfoTextHover, colorBorder },
  } = theme.useToken();

	// Update tax-invoice address
	const handleUpdateAddress = async () => {
		if (!props.cart || !props.cart.invoice) {
			setErrorMessages("Tax invoice address not found!");
			return;
		}

		const myData = await form.validateFields();
		setErrorMessages(null);
		setLoading(true);
		try {
			await PATCH(`${URL_SALE.CART_TAX_INVOICE}${props.cart.invoice.id}/`, myData);
			message.success("Update address successfully");
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	// Update payment-detail (totalDiscount, netPrice)
	const updatePaymentDetail = async (discountList) => {
		if (!props.cart || !props.cart.invoice) {
			setErrorMessages("Cart not found!");
			return;
		}

		setErrorMessages(null);
		try {
			const response = await POST(
				`${URL_SALE.CART}${props.cart.id}/payment-detail/`, 
				{ discounts: discountList }
			);
			setPaid(response.data.net_price);
			setDiscount(response.data.total_discount);
			setDiscountList(discountList);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open && props.cart) {
			if (props.cart.invoice) {
				form.setFieldsValue({...props.cart.invoice});
			}
			setPaid(props.cart.net_price ? parseFloat(props.cart.net_price) : 0);
		} else {
			if (!props.open) {
				form.resetFields();
				setErrorMessages(null);
			}
		}
	}, [props.open, props.cart]);

	return (
		<Drawer 
			open={props.open}
			onClose={props.onClose}
			width={"80%"}
			title={"ชำระเงิน / Payment"}>

			{/* Tax Invoice */}
			<SegmentHeader 
				header={"ใบกำกับภาษี / Tax Invoice"}
				content={
					<Form 
						form={form}
						layout="vertical"
						autoComplete="off">
						{errorMessages && 
							<Alert
								message={errorMessages}
								type="error"
								showIcon
								style={{marginBottom: "8px", textAlign: "left"}}/>
						}

						<Row gutter={16}>
							<Col md={5} sm={24} xs={24}>
								<Form.Item name="is_head_office" valuePropName="checked">
									<Checkbox>Head Office</Checkbox>
								</Form.Item>
							</Col>
							<Col md={5} sm={24} xs={24}>
								<Form.Item name="is_branch_office" valuePropName="checked">
									<Checkbox>Branch</Checkbox>
								</Form.Item>
							</Col>
							<Col md={14} sm={24} xs={24} style={{ marginBottom: "auto" }}>
								<Form.Item 
									name="branch_name">
									<Input autoComplete="off"  placeholder='Branch Name...' />
								</Form.Item>
							</Col>
							<Col md={16} sm={24} xs={24}>
								<Form.Item 
									name={"invoice_name"}
									label="ชื่อ / Name">
									<Input autoComplete="off" />
								</Form.Item>
							</Col>
							<Col md={8} sm={24} xs={24}>
								<Form.Item 
									name={"invoice_id"}
									label="เลขประจำตัวผู้เสียภาษี / Tax ID">
									<Input autoComplete="off" />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item 
									name={"invoice_address"}
									label="ที่อยู่ / Address" >
									<Input.TextArea />
								</Form.Item>
							</Col>
						</Row>

						<div style={{textAlign: "center"}}>
							<Button
								loading={isLoading}
								style={{backgroundColor: colorWarning}}
								type={"primary"}
								shape={"round"}
								onClick={handleUpdateAddress}><EditOutlined />{"Update"}
							</Button>
						</div>
					</Form>
				}/>
			
			{/* Discount */}
			<DiscountSegment 
				style={{marginTop: 16}}
				member={props.member}
				cart={props.cart}
				open={props.open}
				onDiscountChange={(list) => updatePaymentDetail(list)}/>

			{/* Payment method */}
			<PaymentMethodSegment 
				style={{marginTop: 16}}
				open={props.open}
				balanceAmount={paid-payment}
				onPaymentMethodChange={(amount, list) => {
					setPayment(amount);
					setPaymentMethodList(list);
				}}/>

			<Row gutter={[24, 8]} style={{padding: 0, marginTop: 16}}>
				<Col md={12} sm={24} xs={24}>
					<Input 
						size="large"
						inputReadOnly={true}
						className={"custom-addon font-weight-bold-input-group"}
						style={{textAlign: "right"}}
						addonBefore={"Sub Total:"}
						addonAfter={"Baht"}
						value={currencyFormat(props.cart ? props.cart.net_price: 0)}/>
				</Col>
				<Col md={12} sm={24} xs={24}>
					<Input 
						size="large"
						inputReadOnly={true}
						className={"custom-addon font-weight-bold-input-group"}
						style={{textAlign: "right"}}
						addonBefore={"Grand Total:"}
						addonAfter={"Baht"}
						value={currencyFormat(paid)}/>
				</Col>
				<Col md={12} sm={24} xs={24}>
					<Input 
						size="large"
						inputReadOnly={true}
						className={"custom-addon font-weight-bold-input-group"}
						style={{textAlign: "right"}}
						addonBefore={"Discount:"}
						addonAfter={"Baht"}
						value={currencyFormat(discount)}/>
				</Col>
				
				<Col md={12} sm={24} xs={24}>
					<Button
						size="large"
						disabled={(payment != paid)}
						style={{background: (payment != paid) ? colorBorder : colorInfoTextHover, width: "100%"}}
						type="primary"
						onClick={() => setOpenModal(true)}
						icon={<DollarOutlined />}>
						Paid & Print Receipt
					</Button>
				</Col>
			</Row>
			{ payment > paid && <Alert style={{ marginTop: '1rem' }} type='warning' showIcon description='จำนวนเงินที่จ่ายเกินราคา'/>}
			<PaymentModal
				open={openModal}
				cartID={props.cart ? props.cart.id : null}
				is_required={props.cart ? props.cart.is_consent_required: true }
				send_email_show={props.cart ? props.cart.is_consent_required || props.cart.is_consent_agreed : false}
				paid={paid}
				received={payment}
				discounts={discountList}
				payments={paymentMethodList}
				onClose={() => setOpenModal(false)}
				onSuccess={() => {
					setOpenModal(false);
					props.onSuccess();
				}}/>
		</Drawer>
	)
}

PaymentDrawer.defaultProps = {
	cart: null,
	open: false,
	onClose: () => {},
	onSuccess: () => {},
}

PaymentDrawer.propTypes = {
	cart: propTypes.object,
	open: propTypes.bool,
	onClose: propTypes.func,
	onSuccess: propTypes.func,
}