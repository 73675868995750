import React from "react";
import { Button, Card, Layout, Select, Typography, Image, Row, Spin } from "antd";
import { PATH } from "../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";
import './CssScreen/KioskVDO.css'
import vdo from './CssScreen/AHC-KIOSK1.webm';
import MenuLanguageSelect from "./kiosk-components/MenuLanguageSelect";
import { useTranslation } from "react-i18next";

export default function KioskVideoScreen() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate();
  const gotoLoginPage = () => {
    navigate(PATH.KIOSK_LOGIN)
  }

  const { Header, Footer, Sider, Content } = Layout;
  const { Text } = Typography;

  const setDefaultLng = async () => {
    try {
      await i18n.changeLanguage('en');
    } finally {

    }
  }

  React.useEffect(() => {
    setDefaultLng();
  }, [])

  React.useEffect(() => {
    const video = document.getElementById('myVideo'); 
    video.addEventListener('play', () => {
      setLoading(false)
  });
  },[])


  return (
      <Layout className="video-cover">
        {loading && (
        <div 
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 10,
          }}
        />)}
        <div className="video-background">
          <video className='videoTag' id="myVideo" autoPlay loop muted>
          <source src={vdo} type='video/webm' />
          </video>
        </div>
        <Row className="content">
          <MenuLanguageSelect />
        </Row>
      </Layout>

  )
}
