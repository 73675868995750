
import Cookies from "js-cookie";
import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ClassDetailScreen from "../screens/ClassDetailScreen";
import ConsentScreen from "../screens/ConsentScreen";
import FreeTrainingScreen from "../screens/FreeTrainingScreen";
import LoginScreen from "../screens/LoginScreen";
import MemberDetailScreen from "../screens/MemberDetailScreen";
import MemberMenuScreen from "../screens/member/MemberMenuScreen";
import MenuScreen from "../screens/MenuScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import RegisterScreen from "../screens/RegisterScreen";
import PrivateOutlet from "./PrivateOutlet";
import PrivateOutletKiosk from "./PrivateOutletKiosk";
import PackagesHistoryScreen from "../screens/member/PackagesHistoryScreen";
import SecondaryScreen from "../screens/SecondaryScreen";
import LiffLoginScreen from "../screens/member/LiffLoginScreen";
import VerifyPhoneResultScreen from "../screens/VerifyPhoneResultScreen";
import SignContractScreen from "../screens/SignContractScreen";
import ComingSoonScreen from "../screens/member/ComingSoonScreen";
import GateCheckInScreen from "../screens/GateCheckInScreen";
import GateCheckOutScreen from "../screens/GateCheckOutScreen";
import PromptpayQRScreen from "../screens/PromptpayQRScreen";
import KioskVideoScreen from "../screens/kiosk/KioskVideoScreen";
import KioskLoginScreen from "../screens/kiosk/KioskLoginScreen";
import KioskUser from "../screens/kiosk/KioskUser";
import KioskMenuPackages from "../screens/kiosk/KioskMenuPackages";
import KioskWaitingPayment from "../screens/kiosk/kiosk-components/KioskWaitingPayment";
import KioskPaymentSuccess from "../screens/kiosk/kiosk-components/KioskPaymentSuccess";
import KioskPaymentMethod from "../screens/kiosk/KioskPaymentMethod"
import KioskSignContrackScreen from "../screens/kiosk/KioskSignContractScreen";



export const PATH = {
  LOGIN: "/login",
  MAIN: "/",
  MENU: "/menu",
  CONSENT: "/consent",
  CONTRACT_SIGN: "/contract-sign",
  PROMPTPAY_QR: "/promptpay-qr",
  REGISTER: "/register",
  VERIFY_SMS: "/verify-sms-result",
  MEMBER_DETAIL: "/member-detail",
  MEMBER_INFO: "/info",
  MEMBER_CHECKIN: "/checkin",
  PACKAGES: "/packages",
  MEMBER_REWARD: "/reward",
  CART: "/cart",
  CLASS_DETAIL: "/class-detail",
  FREE_TRAINING: "/free-training", 
  SECONDARY_SCREEN: "/secondary-screen",
  
  RECEPTION: "/reception",
  MEMBERS: "/members",
  PAYMENTS: "/payments",
  CLASS: "/class",
  CALENDAR: "/calendar",
  PT_SHIFT: "/pt-shift",
  DOOR: "/door",
  LAUNDRY_GATE: "/laundry-gate",
  REPORTS: "/reports",
  
  // For member
  LIFF_LANDING: '/liff/:app',
  MEMBER: "/member",
  PROFILE: "/profile",
  MEMBER_PACKAGES: "/member-packages",
  MEMBER_CALENDAR: "/member-calendar",
  MEMBER_BOOKING: "/member-booking",
  PACKAGES_HISTORY: "/packages-history",
  COMING_SOON: "/coming-soon",

  // Gate
  GATE_IN: "/gin",
  GATE_OUT: "/gout",

  // Kiosk
  KIOSK: "/kiosk",  
  KIOSK_LOGIN: "/kiosk-login",
  KIOSK_USER: "/kiosk-user",
  KIOSK_MENU_PACKAGES: "/kiosk-menu-packages",
  KIOSK_WAITING_PAYMENT:"/kiosk-waiting-payment",
  KIOSK_PAYMENT_SUCCESS:"/kiosk-payment-success",
  KIOSK_PAYMENT_METHOD:"/kiosk-payment-method",
  KIOSK_CONTRACT_SIGN:"/kiosk-contract-sign"
}

export default function CustomRoutes() {
  return (
    <Routes>
      <Route exact path={PATH.VERIFY_SMS} element={<VerifyPhoneResultScreen/>}/>
      <Route exact path={PATH.LOGIN} element={<LoginScreen/>} />
      <Route exact path={PATH.CONSENT} element={<ConsentScreen />} />
      <Route exact path={PATH.REGISTER} element={<RegisterScreen />} />
      <Route exact path={`${PATH.LIFF_LANDING}`} element={<LiffLoginScreen />} />
      <Route exact path={`${PATH.CONTRACT_SIGN}/:id/:type`} element={<SignContractScreen/>} />
      <Route exact path={`${PATH.PROMPTPAY_QR}/:amount`} element={<PromptpayQRScreen/>} />
      {/* For kiosk */}
      <Route exact path={PATH.KIOSK} element={<KioskVideoScreen />} />
      <Route exact path={PATH.KIOSK_LOGIN} element={<KioskLoginScreen/>} />
      
      <Route path={PATH.MAIN} element={<PrivateOutletKiosk/>}>
        <Route exact path={PATH.KIOSK_USER} element={<KioskUser/>} />
        {/* <Route index element={<KioskUser />} /> */}
        {/* <Route path="/:menu/*" element={<KioskUser />} /> */}
        <Route exact path={PATH.KIOSK_MENU_PACKAGES} element={<KioskMenuPackages/>} />
        <Route exact path={`${PATH.KIOSK_WAITING_PAYMENT}/:type/:packageId/:ptype`} element={<KioskWaitingPayment/>} />
        <Route exact path={PATH.KIOSK_PAYMENT_SUCCESS} element={<KioskPaymentSuccess/>} />
        <Route exact path={PATH.KIOSK_MENU_PACKAGES} element={<KioskMenuPackages />} />
        <Route exact path={`${PATH.KIOSK_PAYMENT_METHOD}/:packageId/:type`} element={<KioskPaymentMethod />} />
        <Route exact path={`${PATH.KIOSK_CONTRACT_SIGN}/:packageId/:id/:type`} element={<KioskSignContrackScreen />} />
      </Route>
    


      <Route path={PATH.MAIN} element={<PrivateOutlet/>}>
        <Route index element={<MenuScreen />} />
        <Route path="/:menu/*" element={<MenuScreen />} />
        <Route exact path={`${PATH.MEMBER_DETAIL}/:menu/:id`} element={<MemberDetailScreen />} />
        <Route exact path={`${PATH.CLASS_DETAIL}/:id`} element={<ClassDetailScreen />} />
        <Route exact path={`${PATH.FREE_TRAINING}/:id`} element={<FreeTrainingScreen />} />
        <Route exact path={`${PATH.SECONDARY_SCREEN}`} element={<SecondaryScreen/>} />

        {/* For member */}
        <Route exact path={`${PATH.MEMBER}/:menu`} element={<MemberMenuScreen />} />
        <Route exact path={`${PATH.PACKAGES_HISTORY}/:id`} element={<PackagesHistoryScreen />} />
        <Route exact path={PATH.COMING_SOON} element={<ComingSoonScreen />} />

        {/* gate */}
        <Route exact path={PATH.GATE_IN} element={<GateCheckInScreen/>}/>
        <Route exact path={PATH.GATE_OUT} element={<GateCheckOutScreen/>}/>
      </Route>
      
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  )
}